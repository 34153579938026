// @ts-nocheck 
import React from 'react';

export function Download({appstoreUrl,downloadSubTitle,downloadTitle,playstoreUrl}:any) {
  return (
    <div className="cta-section" id="download">
    <div className="container cta-grid">
      <div className="cta-left">
        <img className="section-img" src="img/screen 1.png" />
      </div>
      <div className="cta-right">
        <h4>{downloadTitle}</h4>
        <p className="text">
        { <div dangerouslySetInnerHTML={{ __html: downloadSubTitle }} /> }
        </p>
        <div className="cta">
          <a href={playstoreUrl} target="_blank" rel="noopener noreferrer" className="btn">
            <div className="icon"><ion-icon className="icon" name="logo-android" /></div>
            <div>
              <p><strong>Get it on the</strong></p>
              <p className="md-text">Google Play</p>
            </div>
          </a>
          <a href={appstoreUrl} target="_blank" rel="noopener noreferrer" className="btn">
            <div className="icon"><ion-icon className="icon" name="logo-apple" /></div>
            <div>
              <p><strong>Available on the</strong></p>
              <p className="md-text">Apple Store</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  );
}
