import React from 'react';
import { useDocs } from '../hooks/useDocs';
import { Loading } from '../components/LoadingModal';

export default function DownloadScreen(){ 
    const {loading,landing} = useDocs();

    
    React.useEffect(() => {
        if(loading || !landing.appstoreUrl || !landing.playstoreUrl){ return }
        if( /Android/i.test(navigator.userAgent) ) {
            window.location.replace(landing.playstoreUrl);
            return;
        }
        else if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
            window.location.replace(landing.appstoreUrl);
        }
    }, [loading, landing]);

    return(<Loading visible={true}/>)
}