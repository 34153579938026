import React from 'react';
import { Firestore,Functions } from '../constants/Firebase';
import { AppContext } from '../context';
import { useDailyTotals } from './useDailyTotals';

const {useEffect,useState}=React;

const DefaultToggleVal = '0'//'50%';
 
export const useSpendingCalculator = (start='true',uid:string|null=null)=>{
    const calculatePlanFunction = Functions.httpsCallable(Functions.getFunctions(),'calculatePlanv2');
    const calculatePlanWithoutOptimizationFunction = Functions.httpsCallable(Functions.getFunctions(),'calculatePlanWithoutOptimizationv2');
    const MaxIncome = 83333;
    const MinIncome = 1000;
    const DefalutIncome =  0;//622500;
    const Step = 25;
    const {state} = React.useContext(AppContext);
    const {user} = state;
    const userId = uid ?? user.id;
    const {labels,totals} = useDailyTotals();
    //const {loading,error,errorMessage,liabilities} = useLiabilities(userId,true);
    const [toggleVal,setToggleVal] = React.useState<string>();
    const [share,setShare] = React.useState([0,0]);
    const [payPlan, setPayPlan] = React.useState<any[]>([]);
    const [months, setMonths] = React.useState<string[]>([]);
    const [loadingPlan,setLoadingPlan] = React.useState(false);
    const [modalIncome,setModalIncome] = React.useState(0);
    const [liabTotals,setLiabTotals] = React.useState([]);
    const [totalWithoutPlan,setTotalWithoutPlan] = React.useState(0);
    const [percentReductionTime,setPercentReductionTime] = React.useState(0);
    const [savingAmount,setSavingAmount] = React.useState(0);
    const [errMessage,setErrorMessage] = React.useState('');
    const [liabilities,setLiabilities] = React.useState([]);
    const [useBlizzard,setUseBlizzard] = React.useState(false);

    function calculateToCreditors(total:number,percent:string=DefaultToggleVal,refresh=false){
        const rate = parseFloat(percent) / 100.0;
        const toCreditors = Number(total * rate);
        const spending = Number(total - Number(toCreditors));
        //setShare([toCreditors,spending]);
        calculatePlanbak( Number(toCreditors), percent, total,refresh );
        return toCreditors;
    }

    async function planWithoutOptimization(percent:number){
        try{
            setLoadingPlan(true);
            const results:any = await calculatePlanWithoutOptimizationFunction({paymentPlanPercent:percent,userId});
            const {monthCount,totalPayments} = results.data;
            setTotalWithoutPlan(monthCount);
            await getSavings(percent.toString());
        }
        catch(error){
            console.log('planWithoutOptimizationErr: ', error);
        }
        finally{
            setLoadingPlan(false);
        }
    }

    async function calculatePlanbak(toCreditors=0, percent:string, modalIncome:number,refresh=false){
        //if(toCreditors <= 0) return;
        setErrorMessage('');
        setLoadingPlan(true);

        try{
            const res:any = await calculatePlanFunction({liabilities:[],toCreditors:Number(toCreditors),percent:percent,modalIncome:modalIncome,refresh:refresh,userId,useBlizzard:useBlizzard});
            let data:any = res.data;
            const results:any = await calculatePlanWithoutOptimizationFunction({liabilities:[],modalIncome:data.modalIncome,paymentPlanPercent:percent,userId}).catch((error)=>{
                return {
                    data:{
                        monthCount:0,
                        totalPayments:0,
                    }
                };
            });
            const {monthCount: count} = results.data;
            const plan = res.data.plan;
            res.data.totalWithoutPlan = results.data;
            res.data.percentReductionTime = Number( ( ( (count-(plan.length)) / count ) * 100 ).toFixed(0) );
            
            data = res.data;
            const {monthCount,totalPayments} = data.totalWithoutPlan;
            setMonths(data.months);
            if(!data.success) {
                setErrorMessage(data.message);
                //setLoadingPlan(false);
                setTotalWithoutPlan(0);
                setPercentReductionTime(0);
                setPayPlan([]);
                setSavingAmount( 0 );
                const spending = Number(data.modalIncome - Number(data.toCreditors));
                setShare([data.toCreditors,spending]);
                setModalIncome(data.modalIncome);
                setLiabilities(data.liabilities);
                return;
            }
            setTotalWithoutPlan(monthCount);
            setPercentReductionTime(data.percentReductionTime);
            setPayPlan([...data.plan]);
            setModalIncome(data.modalIncome);
            setLiabTotals(data.libPayed);
            const saving = Number(totalPayments) - Number(data.totalPayments);
            setSavingAmount( saving );
            const monthsSaved = monthCount - (data.plan.length);
            updateStats(saving,monthsSaved);
            //console.log('totalPayments: ', totalPayments);
            const spending = Number(data.modalIncome - Number(data.toCreditors));
            setShare([data.toCreditors,spending]);
            setLiabilities(data.liabilities);
            //setLoadingPlan(false);
        }
        catch(error:any){
            console.log('calPlan',error.message);
            if( error.code && error.code == 'invalid-argument' ){
                alert(error.message);
            }
            setLoadingPlan(false);
        }
        finally{
            setLoadingPlan(false);
        }
    }

    async function updateIncome(income:number,percent:string) {
        calculateToCreditors((income*100),percent,true);
    }

    async function getSavings(percent:string){
        const docRef = Firestore.doc(Firestore.getFirestore(),'Users/'+userId+'/PaymentOptimizer/'+percent);
        return Firestore.getDoc(docRef)
            .then((doc)=>{
                const data = doc.data()??null;
                if(!data) return;
                if( data?.monthCount && data?.plan ){
                    setPercentReductionTime( Number( ( ( (data.monthCount-(data.plan)) / data.monthCount ) * 100 ).toFixed(0) ) );
                }
                if( data.totalPayments && data.totalPaymentsOptimized ){
                    setSavingAmount( data.totalPayments - data.totalPaymentsOptimized );
                }
            })
            .catch((error)=>{})
    }

    async function updateStats(totalSavings:number,totalMonthsSaved:number){
        try{
            if(totalSavings <= 0) return;
            const savingsRef = Firestore.doc(Firestore.getFirestore(),'UsersStats/totalSavingsAmount/users/'+userId);

            await Firestore.runTransaction(Firestore.getFirestore(),async (transaction)=>{
                const sfDoc = await transaction.get(savingsRef);
                if (sfDoc.exists()) {
                    if(totalSavings <= sfDoc.data().savingsAmount){
                        totalSavings = sfDoc.data().savingsAmount;
                        return;
                    }
                }
                transaction.set(savingsRef, { 
                    savingsAmount:totalSavings,
                    totalMonths:totalMonthsSaved
                },{merge:true});
            });
        }
        catch(error){
            console.log('updateStatsErr: ', error);
        }
    }

    useEffect(()=>{
        setLoadingPlan(true);
        const collectionRef = Firestore.collection(Firestore.getFirestore(),'Users/'+userId+'/Settings');
        const docRef = Firestore.doc(collectionRef,'paymentPlanPercent');
        Firestore.getDoc(docRef).then((doc)=>{
            const data = doc.data()??null;
            let tog = DefaultToggleVal;
            if(data && data.paymentPlanPercent) tog = data.paymentPlanPercent+'%';
            setLoadingPlan(false);
            setUseBlizzard(data?.useBlizzard??false);
            setToggleVal( tog );
            if(start!='true') {
                planWithoutOptimization(parseFloat(tog));
                return;
            }    
        })
        .catch((error)=>{
            console.log('paymentPlanPercentErr: ', error.message);
            setLoadingPlan(false);
        });
    },[start]);
 
    useEffect(()=>{
        if(start!='true') return;
        if( loadingPlan) return;
        calculateToCreditors(modalIncome ?? 0,toggleVal,true);
    },[toggleVal,useBlizzard]);

    return {errMessage,MaxIncome,MinIncome,liabilities,liabTotals,loadingPlan,modalIncome,months,payPlan,percentReductionTime,savingAmount,share,Step,totals,totalWithoutPlan,toggleVal,useBlizzard,calculateToCreditors,planWithoutOptimization,setModalIncome,setToggleVal,setUseBlizzard,updateIncome};
}