//TODO: file needs a refactor
import * as Linking from 'expo-linking';
import Strings from '../constants/Strings';
import {LightTheme} from '../constants/Theme';

import AccountsScreen from '../screens/AccountsScreen';
import AccountTransactionsScreen from '../screens/AccountTransactionsScreen';
import AccountTransactionsScreen2 from '../screens/AccountTransactionsScreen.web';
import AddAccountsScreen from '../screens/AddAccountsScreen';
import AddBillScreen from '../screens/AddBillScreen';
import AddBudgetScreen from '../screens/AddBudgetScreen';
import AddProperties from '../screens/AddProperties';
import AddTransactionsScreen from '../screens/AddTransactionsScreen';
import BanksScreen from '../screens/BanksScreen';
import BillsScreen from '../screens/BillsScreen';
import BudgetScreen from '../screens/BudgetScreen';
//@ts-ignore
import DashboardScreenWeb from '../screens/DashboardScreen.web';
import DocsScreen from '../screens/DocsScreen';
import EditDocs from '../screens/EditDocs.web';
import EditLandingScreen from '../screens/EditLandingScreen';
import Error403Screen from '../screens/Error403Screen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import HomeScreen from '../screens/HomeScreen';
import LandingPage from '../screens/LandingPage.web';
import MonthlyScreen from '../screens/MonthlyScreen';
import NotFound from '../screens/NotFoundScreen';
import Onboarding from '../screens/Onboarding';
import PlaidRefreshBank from '../screens/PladRefreshBank';
import PlaidRedirect from '../screens/PlaidRedirect.web';
import PlaidScreen from '../screens/PlaidScreen';
import ProfileScreen from '../screens/ProfileScreen';
import SettingsScreen from '../screens/SettingsScreen';
import SignInScreen from '../screens/SignInScreen';
import SignUpScreen from '../screens/SignUpScreen';
import PaymentOptimizer from '../screens/PaymentOptimizer';
import SpendingCategory from '../screens/SpendingCategory';
import SubscriptionsScreen from '../screens/Subscriptions';
import TransactionsScreen from '../screens/TransactionsScreen';
import UnverifiedScreen from '../screens/UnverifiedScreen';
import UserScreen from '../screens/UserScreen.web';
import ViewAccountDataScreen from '../screens/ViewAccountDataScreen';
import ViewAccountScreen from '../screens/ViewAccountScreen';
import ViewBillScreen from '../screens/ViewBillScreen';
import ViewBudgetScreen from '../screens/ViewBudgetScreen';
import ViewTransactionsScreen from '../screens/ViewTransactionsScreen';
import ValidateTransfersScreen from '../screens/ValidateTransfersScreen';
import DownloadScreen from '../screens/DownloadScreen.web';

export const RouteConfig = {
    screens: {
        Landing: '/',
        Dashboard: '/dashboard',
        Docs: 'docs',
        Doc: 'docs/:doc',
        Documents:'d/:doc',
        Download:'download',
        EditLanding:'editLanding',
        PlaidRedirect: 'plaidredirect',
        User:'user/:id',
        'Account Transactions': 'user/:uid/account/:id/transactions',
        'Payment Optimizer': 'optimizer/:uid',

        NotFound: '*',
    }
}

export const linking = {
    prefixes: [Linking.createURL('/')],
    config: RouteConfig,
}

export const AuthRoutes = [
    {
        key: 'onBoarding',
        component: Onboarding,
        isSecured: false,
        name: 'Onboarding',
        options: {headerShown:false}
    },
    {
        key: 'signin',
        component: SignInScreen,
        isSecured: false,
        name: 'Sign In',
        options: {headerShown:false}
    },
    {
        key: 'signup',
        component: SignUpScreen,
        isSecured: false,
        name: 'Sign Up',
        options: {headerShown:false}
    },
    {
        key: 'forgotpassword',
        component: ForgotPasswordScreen,
        isSecure: false,
        name: 'Forgot Password',
        options: {}
    },
    {
        key: 'docs',
        component: DocsScreen,
        isSecure: false,
        name: 'Docs',
        options: {}
    },
    {
        key: 'plaid',
        component: PlaidScreen,
        isSecured: true,
        name: 'Plaid',
        options: ({ route }:{route:any}) => {
            let type = route?.params?.type??false;
            type = type==='Credit'?'Credit Card':type;
            const title = type?'Connect '+type+' Account':'Connect An Account' ;
            return {title}
        }
    },
    {
        key: 'profile',
        component: ProfileScreen,
        isSecured: true,
        name: 'Profile',
        options: {}
    },
    {
        key: 'settings',
        component: SettingsScreen,
        isSecured: true,
        name: 'Settings',
        options: {}
    },
]

export const UnverifiedRoutes = [
    {
        key:'unverified',
        component: UnverifiedScreen,
        name: 'UnverifiedScreen',
        options: {headerShown:false}
    }
]

export const MainRoutes = [
    {
        key: 'addAccount',
        component: AddAccountsScreen,
        isSecured: true,
        name: 'Add Account',
        options: {}
    },
    {
        key: 'addBill',
        component: AddBillScreen,
        isSecured: true,
        name: 'Add Bill',
        options: {}
    },
    {
        key: 'addBudget',
        component: AddBudgetScreen,
        isSecured: true,
        name: 'Add Budget',
        options: {}
    },
    {
        key: 'addProperties',
        component: AddProperties,
        isSecured: true,
        name: 'Add Properties',
        options: {
            title: 'Add Real Estate'
        }
    },
    {
        key: 'addTransactions',
        component: AddTransactionsScreen,
        isSecured: true,
        name: 'Add Transaction',
        options: {}
    },
    {
        key: 'accountTransactions',
        component: AccountTransactionsScreen,
        isSecured: true,
        name: 'Account Transactions',
        options: {}
    },
    {
        key: 'accounts',
        component: AccountsScreen,
        isSecured: true,
        name: 'Accounts',
        options: {}
    },
    {
        key: 'banks',
        component: BanksScreen,
        isSecured: true,
        name: 'Banks',
        options: {}
    },
    {
        key: 'bills',
        component: BillsScreen,
        isSecured: true,
        name: 'Bills',
        options: {}
    },
    {
        key: 'budget',
        component: BudgetScreen,
        isSecured: true,
        name: 'Budget',
        options: {}
    },
    {
        key: 'docs',
        component: DocsScreen,
        isSecure: false,
        name: 'Docs',
        options: {}
    },
    {
        key: 'monthly',
        component: MonthlyScreen,
        isSecured: true,
        name: 'Monthly',
        options: {}
    },
    {
        key: 'home',
        component: HomeScreen,
        isSecured: true,
        name: 'Home',
        options: {          
            headerStyle: {
                backgroundColor: LightTheme.colors.headerBackground,
            },
            headerTintColor: LightTheme.colors.background,
            headerTitleStyle: {
                color: LightTheme.colors.accent,
                fontSize: 20,
                fontWeight: 'bold',
            },
            title: 'Legacy'
        }
    },
    {
        key: 'plaid',
        component: PlaidScreen,
        isSecured: true,
        name: 'Plaid',
        options: ({ route }:{route:any}) => {
            let type = route?.params?.type??false;
            type = type==='Credit'?'Credit Card':type;
            const title = type?'Connect '+type+' Account':'Connect An Account' ;
            return {title}
        }
    },
    {
        key: 'plaidRefreshBank',
        component: PlaidRefreshBank,
        isSecured: true,
        name: 'plaidrefreshbank',
        options: {
            headerTitle: 'Refresh Bank Login'
        }
    },
    {
        key:'spendingCategory',
        component: SpendingCategory,
        isSecured: true,
        name: 'Spending Category',
        options: {}
    },
    {
        key:'spendingCalculator',
        component: PaymentOptimizer,
        isSecured: true,
        name: 'Payment Optimizer',
        options: {}
    },
    {
        key: 'profile',
        component: ProfileScreen,
        isSecured: true,
        name: 'Profile',
        options: {}
    },
    {
        key: 'settings',
        component: SettingsScreen,
        isSecured: true,
        name: 'Settings',
        options: {}
    },
    {
        key: 'subscriptions',
        component: SubscriptionsScreen,
        isSecure: true,
        name: 'Subscriptions',
        options: {}
    },
    {
        key: 'transactions',
        component: TransactionsScreen,
        isSecured: true,
        name: 'Transactions',
        options: {}
    },
    {
        key:'unverified',
        component: UnverifiedScreen,
        name: 'UnverifiedScreen',
        options: {}
    },
    {
        key:'validatetransfers',
        component: ValidateTransfersScreen,
        isSecure: true,
        name: 'ValidateTransfersScreen',
        options:{
            headerTitle: 'Validate Internal Transfers'
        }
    },
    {
        key:'viewaccount',
        component: ViewAccountScreen,
        name:'View Account',
        options:{}
    },
    {
        key:'viewaccountdata',
        component: ViewAccountDataScreen,
        name:'View Account Data',
        options:{}
    },
    {
        key:'viewbill',
        component: ViewBillScreen,
        name:'View Bill',
        options:{}
    },
    {
        key:'viewbudget',
        component: ViewBudgetScreen,
        name: 'View Budget',
        options:{}
    },
    {
        key:'viewtransactions',
        component: ViewTransactionsScreen,
        name:'View Transactions',
        options:{}
    }
]

export const MainWebRoutes = [
    {
        key: 'accountTransactions',
        component: AccountTransactionsScreen2,
        isSecured: true,
        name: 'Account Transactions',
        options: {}
    },
    {
        key: 'dashboard',
        component: DashboardScreenWeb,
        isSecured: true,
        name: 'Dashboard',
        options: {}
    },
    {
        key: 'docs',
        component: DocsScreen,
        isSecure: false,
        name: 'Documents',
        options: {}
    },
    {
        key:'download',
        component: DownloadScreen,
        isSecure: false,
        name: 'Download',
        options: {}
    },
    {
        key: 'editDocs',
        component: EditDocs,
        isSecured: true,
        name: 'Docs',
        options: {}
    },
    {
        key: 'editDocs2',
        component: EditDocs,
        isSecured: true,
        name: 'Doc',
        options: {}
    },
    {
        key:'editLanding',
        component: EditLandingScreen,
        isSecured: true,
        name:'EditLanding',
        Options: {}
    },
    {
        key: 'error403',
        component: Error403Screen,
        isSecured: false,
        name: 'Error',
        options: {}
    },
    {
        key: 'home',
        component: LandingPage,
        isSecured: false,
        name: 'Landing',
        options: {
            headerShown: false,
            title: Strings.HOME_TITLE,
        }
    },
    {
        key:'spendingCalculator',
        component: PaymentOptimizer,
        isSecured: true,
        name: 'Payment Optimizer',
        options: {}
    },
    {
        key: 'plaidredirect',
        component: PlaidRedirect,
        isSecured: false,
        name: 'PlaidRedirect',
        options: {
            headerShown: false,
            title: 'Plaid Redirect',
        }

    },
    {
        key: 'notFound',
        component: NotFound,
        isSecured: false,
        name: 'NotFound',
    },
    {
        key: 'user',
        component: UserScreen,
        isSecured: true,
        name: 'User',
    },
];

export const RefreshBankRoutes = [
    {
        key: 'plaidRefreshBank',
        component: PlaidRefreshBank,
        isSecured: true,
        name: 'plaidrefreshbank',
        options: {
            headerTitle: 'Refresh Bank Login'
        }
    },
    
];